/**axios封装
 * 请求拦截、相应拦截、错误统一处理
 */
import axios from 'axios';
import QS from 'qs';
import { showToast } from 'vant';
import store from "@/store";
import baseUrl from '@/config'
import router from '../router/index'

axios.defaults.baseURL = baseUrl
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';
// axios.defaults.headers.post['Content-Type'] = "multipart/form-data";

// 请求超时时间
axios.defaults.timeout = 10000;
// const service = axios.create({
//     //baseURL:config.baseURL
//     timeout: 10000,
// });

// post请求头

// 请求拦截器
axios.interceptors.request.use(    
    config => { 
        // 每次发送请求之前判断是否存在token，如果存在，则统一在http请求的header都加上token，不用每次请求都手动添加了
        // 即使本地存在token，也有可能token是过期的，所以在响应拦截器中要对返回状态进行判断
        const token = store.getters.token;     
        console.log('store.state.token', store.getters.token)   
        config.headers.Authorization = `Bearer ${store.getters.token}`;        
        return config;    
    },    
    error => {        
        return Promise.error(error);    
    })

// 响应拦截器
axios.interceptors.response.use(    
    response => {        
        console.log('response', response)
        if (response.status === 200) {            
            if(response.data.code == 0 || !response.data.code) {
                console.log('返回正确',response )
                return Promise.resolve(response);   
            } else if(response.data.code == 'TOKEN-ILLEGAL' || response.data.code == 'USER-INVALID' || response.data.code == 'TOKEN-EXPIRED') {
                showToast(response.data.message)
                return Promise.reject(response);
            } else {
              // console.log('返回错误',response )
              // showToast(response.data.message)
              return Promise.resolve(response);
            }   
        } else {         
            console.log('不等于')   
            return Promise.reject(response);        
        }    
    },
    // 服务器状态码不是200的情况    
    error => {       
        console.log('error', error) 
        if (error.response.status) {            
            switch (error.response.status) {                
                // 401: 未登录                
                // 未登录则跳转登录页面，并携带当前页面的路径                
                // 在登录成功后返回当前页面，这一步需要在登录页操作。                
                case 401:                    
                // router.replace({                        
                //         path: '/login',                        
                //         query: { redirect: router.currentRoute.fullPath } 
                //     });
                //     break;
                // 403 token过期                
                // 登录过期对用户进行提示                
                // 清除本地token和清空vuex中token对象                
                // 跳转登录页面                
                case 403:                     
                    showToast({                        
                        message: '登录过期，请重新登录',                        
                        duration: 1000,                        
                        forbidClick: true                    
                    });                    
                    // 清除token                    
                    localStorage.removeItem('token');                    
                    store.commit('loginSuccess', null);                    
                    // 跳转登录页面，并将要浏览的页面fullPath传过去，登录成功后跳转需要访问的页面
                    // setTimeout(() => {                        
                    //     router.replace({                            
                    //         path: '/login',                            
                    //         query: { 
                    //             redirect: router.currentRoute.fullPath 
                    //         }                        
                    //     });                    
                    // }, 1000);                    
                    break; 
                // 404请求不存在                
                case 404:                    
                    showToast({                        
                        message: '网络请求不存在',                        
                        duration: 1500,                        
                        forbidClick: true                    
                    });                    
                break;                
                // 其他错误，直接抛出错误提示                
                default:                    
                    showToast({                        
                        message: error.response.data.message,                        
                        duration: 1500,                        
                        forbidClick: true                    
                    });            
            }            
            return Promise.reject(error.response);        
        }       
    }
);
export default axios;