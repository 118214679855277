import { login } from "@/request/api";

const getDefaultState = () => {
  return {
    token: window.localStorage.getItem('APIA-AuthToken'),
    userInfo: {},  // 登录后的用户信息
    currentBankInfo: {}, // 当前银行信息
  }
}

const state = getDefaultState()

const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token
    window.localStorage.setItem('APIA-AuthToken', token)
  },
  SET_USERINFO: (state, userInfo) => {
    state.userInfo = userInfo
  },
  CURRENTBANKINFO: (state, currentBankInfo) => {
    state.currentBankInfo = currentBankInfo
  },
}

const actions = {
  setToken({ commit }, token) {
    commit('SET_TOKEN', token)
  },
  // user login
  login({ commit }, userInfo) {
    return new Promise((resolve, reject) => {
      login(userInfo).then(response => {
        console.log(response);
          commit('SET_TOKEN', response.data.token)
          commit('SET_USERINFO', response.data.user)
          window.localStorage.setItem('userInfo', JSON.stringify(response.data.user));
          window.localStorage.setItem('APIA-AuthToken', response.data.token);
          if(response.ercode == 1) {
            reject(response.data)
          }
          resolve()
      }).catch(error => {
        reject(error)
      })
    })
  },
  currentBankInfo({ commit }, currentBankInfo) {
    commit('CURRENTBANKINFO', currentBankInfo)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}