import { createApp } from 'vue'
import App from './App.vue'
import router from './router/index'
import '@/assets/style/index.scss' // global css
import VueCropper from 'vue-cropper'
import store from './store/index'
import './utils/rem'
// 1. 引入你需要的组件
import { Image, Button,Checkbox,CheckboxGroup, ContactCard, Radio, RadioGroup, Form, Field, Cell, CellGroup, Switch, Overlay} from 'vant';
// 2. 引入组件样式
import 'vant/lib/index.css';

const app = createApp(App);

app.use(Image)
app.use(Button)
app.use(Checkbox)
app.use(CheckboxGroup)
app.use(Radio)
app.use(RadioGroup)
app.use(Form)
app.use(Field)
app.use(Cell);
app.use(CellGroup)
app.use(ContactCard);
app.use(Switch);
app.use(Overlay);

app.use(store)
app.use(router)
app.use(VueCropper)

app.mount("#app");
