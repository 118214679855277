

var baseUrl;
let urlArry = (window.location.search.split('?')[1]).split('&');
let obj = {}
urlArry.map(item => {
  let strArr = item.split('=');
  obj[strArr[0]] = strArr[1];
})
let apiBase = obj.apiBase;
console.log('apiBase', apiBase)

if(process.env.NODE_ENV === 'development') {
    // 开发环境
    baseUrl = '/dev-api/'
    // baseUrl = apiBase ? apiBase + '/' : '/dev-api/'
} else {
    // 生产环境
    baseUrl = apiBase ? apiBase + '/' : 'https://bbr.jiayunkeji.cn/'
    console.log('baseUrl111', baseUrl)
}
export default baseUrl