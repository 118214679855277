
import request from './http';

export const allQuestions = (query) => {
  return request({
    url: '/front/match/allQuestions',
    method: 'get',
    params: query
  })
}

export const JudgeAnswer = (query) => {
  return request({
    url: `/front/match/${query.id}`,
    method: 'get',
    params: query.data
  })
}

export const submitAnswer = (data) => {
  return request({
    url: `/front/match/submit`,
    method: 'post',
    data
  })
}

export const feedback = (data) => {
  return request({
    url: `/front/book`,
    method: 'post',
    data
  })
}

// 排行榜
export const currentRank = (params) => {
  return request({
    url: `/front/match/currentRank`,
    method: 'get',
    params
  })
}

// 排行榜-我的排名
export const myRank = (params) => {
  return request({
    url: `/front/match/myRank`,
    method: 'get',
    params
  })
}




// 绘画列表
export const drawPic = (params) => {
  return request({
    url: `/front/drawPic`,
    method: 'get',
    params
  })
}

// 绘画点赞
export const praise = (id) => {
  return request({
    url: `/front/drawPic/praise/${id}`,
    method: 'get',
  })
}

// 我的点赞信息
export const myPraise = () => {
  return request({
    url: `/front/drawPic/myPraise`,
    method: 'get',
  })
}

// 读后感列表
export const bookList = (params) => {
  return request({
    url: `/front/book`,
    method: 'get',
    params
  })
}

// 读后感添加
export const bookAdd = (data) => {
  return request({
    url: `/front/book`,
    method: 'post',
    headers: {
      "Content-Type": 'application/json',
    },
    data
  })
}

// 活动列表
export const activityList = (params) => {
  return request({
    url: `/front/activity`,
    method: 'get',
    params
  })
}
